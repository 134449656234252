import { Injectable } from '@angular/core';


import * as fromAppServices from '../services';


@Injectable()
export class AuthGuard  {

  constructor(
    public authService: fromAppServices.AuthService,
  ) {}

  canActivate(ars: any, rs: any): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      this.authService.login();
      return false;
    }
  }
}
